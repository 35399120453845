import React from 'react';
import './main.css';  // Make sure the path is correct

const InstructorDashboard = () => {
    return (
        <>
            <nav id="top-navbar">
                <h1>Instructor Dashboard</h1>
            </nav>

            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="/instructor-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/instructor-students"><i className="fas fa-user"></i> Students</a></li>
                        <li><a href="/instructor-grades"><i className="fas fa-clipboard-list"></i> Grades</a></li>
                        <li><a href="/instructor-exams"><i className="fas fa-pen"></i> Exams</a></li>
                        <li><a href="/instructor-course-management"><i className="fas fa-book-open"></i> Course Management</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>
                <main id="main-content">
                    <h5>Course Code - CSE5301</h5>
                    <h5>Subject - Web Development</h5>
                    <h1>List of Students</h1>
                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Student #</th>
                                <th>Full Name</th>
                                <th>Sex</th>
                                <th>Email Address</th>
                                <th>Grade</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Manually entered for now, but consider mapping through a dataset */}
                            <tr>
                                <td>1</td>
                                <td>12345</td>
                                <td>User 1</td>
                                <td>M</td>
                                <td>user1@email.com</td>
                                <td>
                                    <form action="/updateGrade" method="post">
                                        <input type="text" name="grade" defaultValue="A" />
                                    </form>
                                </td>
                                <td>
                                    <button type="submit" formAction="/updateGrade">Update</button>
                                </td>
                            </tr>
                            {/* Similar rows for User 2 and User 3 */}
                        </tbody>
                    </table>
                </main>
            </div>
        </>
    );
};

export default InstructorDashboard;
