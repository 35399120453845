import React from 'react';
import './main.css';  // Make sure this path is correct


const ProgramCoordinatorHome = () => {

    const nextStep = (step) => {
        console.log("Next Step: ", step);
        // Implement your logic here
    };

    return (
        <>
            <nav id="top-navbar">
                <h1>Program Coordinator Dashboard</h1>
            </nav>

            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="pc-home"><i className="fas fa-home"></i> Dashboard</a></li>
                        <li><a href="program-coordinator"><i className="fas fa-tachometer-alt"></i> Profile</a></li>
                        <li><a href="pc-course-management"><i className="fas fa-book"></i> Course Management</a></li>
                        <li><a href="pc-program-management"><i className="fas fa-book"></i> Program Management</a></li>
                        <li><a href="messages-dashboard"><i className="fas fa-clipboard-check"></i> Messages</a></li>
                        <li><a href="homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>

                <main id="main-content">
                    <div id="student-info">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/0/0b/Professor_Steven_Chu_ForMemRS_headshot.jpg"
                            alt="Student Photo" />
                        <p>Name: Ross</p>
                        <p>College: College of Arts and Sciences</p>
                        <p>Department: Computer Science</p>
                        <p>Email: ross@email.com</p>
                        <button type="button" onClick={() => nextStep(1)}>Update Profile</button>
                    </div>
                </main>
            </div>
        </>
    );
};

export default ProgramCoordinatorHome;
