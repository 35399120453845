import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const MessagesApp = () => {
    // Define inline styles
    const userInfoStyle = {
        marginTop: '20px',
    };
    const labelStyle = {
        fontWeight: 'bold',
    };
    const selectInputStyle = {
        width: '100%',
        padding: '8px',
        marginBottom: '10px',
    };
    const searchButtonStyle = {
        display: 'block',
        marginTop: '10px',
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        cursor: 'pointer',
    };
    const chatBoxStyle = {
        display: 'none',
    };

    const activeChatBoxStyle = {
        display: 'block',
    };

    return (
        <>
            <nav id="top-navbar">
                <h1>Messages</h1>
            </nav>
            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="#"><FontAwesomeIcon icon={faEnvelope} /> Messages</a></li>
                        <li><a href="#"><FontAwesomeIcon icon={faSignOutAlt} /> Log out</a></li>
                    </ul>
                </div>

                <main id="main-content">
                    <div id="messenger">
                        <div id="user-info" style={userInfoStyle}>
                            <label for="user-role" style={labelStyle}>Select Role:</label>
                            <select id="user-role" style={selectInputStyle}>
                                <option value="instructor">Instructor</option>
                                <option value="administrator">Administrator</option>
                                <option value="QA">QA</option>
                                <option value="student">Student</option>
                            </select>
                            <label for="user-id" style={labelStyle}>Enter ID:</label>
                            <input type="text" id="user-id" placeholder="Enter your ID or Name" style={selectInputStyle} />
                            <button id="search-button" style={searchButtonStyle}>Search</button>
                        </div>

                        <div className="chat-box" id="chat-instructor" style={chatBoxStyle}>
                            {/* Chat messages with the instructor will be displayed here */}
                        </div>
                        <div className="chat-box" id="chat-administrator" style={chatBoxStyle}>
                            {/* Chat messages with the administrator will be displayed here */}
                        </div>
                        <div className="chat-box" id="chat-qa" style={chatBoxStyle}>
                            {/* Chat messages with the QA will be displayed here */}
                        </div>
                        <div className="chat-box" id="chat-student" style={chatBoxStyle}>
                            {/* Chat messages with the student will be displayed here */}
                        </div>

                        <div id="message-input">
                            <input type="text" id="message-text" placeholder="Type your message..." style={selectInputStyle} />
                            <button id="send-button">Send</button>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default MessagesApp;
