import React, { useState } from 'react';
import './main.css'; // Make sure to adjust the path to your CSS

const Messages = () => {
    const [chat, setChat] = useState([
        { type: 'received', text: 'Hi, how are you?' },
        { type: 'sent', text: "I'm good, thanks!" },
        { type: 'received', text: 'Great! Do you have time to meet?' },
        { type: 'sent', text: 'Sure, what time works for you?' },
    ]);

    const [message, setMessage] = useState('');

    const sendMessage = () => {
        if (message.trim() === '') return;

        setChat([...chat, { type: 'sent', text: message }]);
        setMessage('');
    };

    return (
        <div id="wrapper">
            <nav id="top-navbar">
                <h1>Messages</h1>
            </nav>

            <div id="sidebar">
                <ul>
                    <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                    <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                </ul>
            </div>

            <main id="main-content">
                <div id="messenger">
                    <div id="chat-box">
                        {chat.map((message, index) => (
                            <div key={index} className={`message ${message.type}`}>
                                {message.text}
                            </div>
                        ))}

                        <div id="message-input">
                            <input
                                type="text"
                                id="message-text"
                                placeholder="Type your message..."
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            <button id="send-button" onClick={sendMessage}>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Messages;
