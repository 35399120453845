import React from 'react';
import './main.css';

const InstructorStudents = () => {
    return (
        <>
            <nav id="top-navbar">
                <h1>Instructor Dashboard</h1>
            </nav>

            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="/instructor-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/instructor-students"><i className="fas fa-user"></i> Students</a></li>
                        <li><a href="/instructor-grades"><i className="fas fa-clipboard-list"></i> Grades</a></li>
                        <li><a href="/instructor-exams"><i className="fas fa-pen"></i> Exams</a></li>
                        <li><a href="/instructor-course-management"><i className="fas fa-book-open"></i> Course Management</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>

                <main id="main-content">
                    <h2>Enrolled Students</h2>

                    <table>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Student #</th>
                                <th>Full Name</th>
                                <th>Sex</th>
                                <th>Email Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* I'm keeping it static here, but you'd probably use .map to generate rows from a data array */}
                            <tr>
                                <td>1</td>
                                <td>1002063330</td>
                                <td>Siri</td>
                                <td>F</td>
                                <td>siri@example.com</td>
                            </tr>
                            {/* More rows like this for other students */}
                        </tbody>
                    </table>
                </main>
            </div>
        </>
    );
};

export default InstructorStudents;
