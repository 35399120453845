import React, { useState } from 'react';
import './main.css'; // You'll need to create this CSS file yourself.

const AddCourse = () => {
    const [courseData, setCourseData] = useState({
        courseId: '',
        courseName: '',
        instructor: '',
        enrolledStudents: 0,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCourseData({
            ...courseData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // TODO: Add logic to send this data to your backend.
        console.log("Course Data Submitted: ", courseData);
    };

    return (
        <div className="add-course">
            <h1>Add New Course</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="courseId">Course ID:</label>
                    <input type="text" id="courseId" name="courseId" value={courseData.courseId} onChange={handleInputChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="courseName">Course Name:</label>
                    <input type="text" id="courseName" name="courseName" value={courseData.courseName} onChange={handleInputChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="instructor">Instructor:</label>
                    <input type="text" id="instructor" name="instructor" value={courseData.instructor} onChange={handleInputChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="enrolledStudents">Enrolled Students:</label>
                    <input type="number" id="enrolledStudents" name="enrolledStudents" value={courseData.enrolledStudents} onChange={handleInputChange} />
                </div>

                <button type="submit">Add Course</button>
            </form>
        </div>
    );
};

export default AddCourse;
