import React from 'react';
import './main.css'; // Make sure the path is correct

const StudentExams = () => {
    // You can keep this data in state or fetch it from an API
    const exams = [
        {
            subject: 'Data Structures',
            iconClass: 'fas fa-book-open',
            date: 'Oct 10, 2023',
        },
        {
            subject: 'Algorithms',
            iconClass: 'fas fa-code',
            date: 'Oct 20, 2023',
        },
        // Add more exams here
    ];

    return (
        <div>
            <nav id="top-navbar">
                <h1>Exams</h1>
            </nav>

            <div id="wrapper">

                {/* Side Navigation */}
                <div id="sidebar">
                    <ul>
                        <li><a href="/student-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/student-subjects"><i className="fas fa-book"></i> My Subjects</a></li>
                        <li><a href="/student-grades"><i className="fas fa-star"></i> Grades</a></li>
                        <li><a href="/student-exams"><i className="fas fa-file-alt"></i> Exams</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>

                {/* Main Content */}
                <main id="main-content">
                    <h2 className="page-heading">Upcoming Exams</h2>

                    {exams.map((exam, index) => (
                        <div key={index} className="exam-card">
                            <i className={exam.iconClass + " exam-icon"}></i>
                            <h3> {exam.subject}</h3>
                            <p> Exam Date: {exam.date}</p>
                        </div>
                    ))}

                </main>
            </div>
        </div>
    );
};

export default StudentExams;
