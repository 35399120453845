import React from 'react';
import './main.css';  // Your styles here

const ProgramCoordinatorCourseManagement = () => {
    return (
        <>
            <nav id="top-navbar">
                <h1>Program Coordinator Dashboard</h1>
            </nav>

            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="pc-home"><i className="fas fa-home"></i> Dashboard</a></li>
                        <li><a href="program-coordinator"><i className="fas fa-tachometer-alt"></i> Profile</a></li>
                        <li><a href="pc-course-management"><i className="fas fa-book"></i> Course Management</a></li>
                        <li><a href="pc-program-management"><i className="fas fa-book"></i> Program Management</a></li>
                        <li><a href="messages-dashboard"><i className="fas fa-clipboard-check"></i> Messages</a></li>
                        <li><a href="homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>
                <main id="main-content">
                    <h1>Course Management</h1>
                    <button id="addCourseBtn">Add New Course</button>

                    <h2>Edit Course Content</h2>
                    <label htmlFor="courseToEdit">Select Course:</label>
                    <select id="courseToEdit">
                        <option value="CSE101">CSE101 - Introduction to Computer Science</option>
                    </select>
                    <button id="editCourseContentBtn">Edit Content</button>

                    <h2>Update Syllabus</h2>
                    <label htmlFor="courseForSyllabus">Select Course:</label>
                    <select id="courseForSyllabus">
                        <option value="CSE101">CSE101 - Introduction to Computer Science</option>
                    </select>
                    <button id="uploadSyllabusBtn">Upload Syllabus</button>

                    <table id="courseTable">
                        <thead>
                            <tr>
                                <th>Course ID</th>
                                <th>Course Name</th>
                                <th>Instructor</th>
                                <th>Enrolled Students</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CSE101</td>
                                <td>Introduction to Computer Science</td>
                                <td>ABC</td>
                                <td>30</td>
                                <td>
                                    <button>Edit</button>
                                    <button>Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </main>
            </div>
        </>
    );
};

export default ProgramCoordinatorCourseManagement;
