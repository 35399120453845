import React from 'react';
import './main.css'; // Make sure the path is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog, faBook, faChalkboardTeacher, faUserGraduate, faClipboardList, faSignOutAlt, faFileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const InstructorCourseManagement = () => {
    return (
        <div>
            <head>

                <body>
                    <nav id="top-navbar">
                        <h1>Instructor Dashboard</h1>
                    </nav>
                </body>
            </head>
            <div id="wrapper">

                {/* Side Navigation */}

                <div id="sidebar">
                    <ul>
                        <li><a href="/instructor-dashboard"><FontAwesomeIcon icon={faChalkboardTeacher} /> Instructor Dashboard</a></li>
                        <li><a href="/instructor-students"><FontAwesomeIcon icon={faUserGraduate} /> Students</a></li>
                        <li><a href="/instructor-grades"><FontAwesomeIcon icon={faClipboardList} /> Grades</a></li>
                        <li><a href="/instructor-exams"><FontAwesomeIcon icon={faFileAlt} /> Exams</a></li>
                        <li><a href="/instructor-course-management"><FontAwesomeIcon icon={faBook} /> Course Management</a></li>
                        <li><a href="/messages"><FontAwesomeIcon icon={faEnvelope} /> Messages</a></li>
                        <li><a href="/homepage"><FontAwesomeIcon icon={faSignOutAlt} /> Log out</a></li>
                    </ul>
                </div>


                <main id="main-content">
                    <h1>Course Management</h1>

                    <button id="addCourseBtn">Add New Course</button>

                    <h2>Edit Course Content</h2>
                    <label htmlFor="courseToEdit">Select Course:</label>
                    <select id="courseToEdit">
                        <option value="CSE101">CSE101 - Introduction to Computer Science</option>
                    </select>
                    <button id="editCourseContentBtn">Edit Content</button>

                    <h2>Update Syllabus</h2>
                    <label htmlFor="courseForSyllabus">Select Course:</label>
                    <select id="courseForSyllabus">
                        <option value="CSE101">CSE101 - Introduction to Computer Science</option>
                    </select>
                    <button id="uploadSyllabusBtn">Upload Syllabus</button>

                    <table id="courseTable">
                        <thead>
                            <tr>
                                <th>Course ID</th>
                                <th>Course Name</th>
                                <th>Instructor</th>
                                <th>Enrolled Students</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CSE101</td>
                                <td>Introduction to Computer Science</td>
                                <td>ABC</td>
                                <td>30</td>
                                <td>
                                    <button>Edit</button>
                                    <button>Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </main>
            </div>
        </div>
    );
};

export default InstructorCourseManagement;
