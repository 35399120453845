import React from 'react';
import './main.css'; // Make sure the path is correct

const InstructorDashboard = () => {
    return (
        <>
            <nav id="top-navbar">
                <h1>Instructor Dashboard</h1>
            </nav>

            <div id="wrapper">
                {/* Side Navigation */}
                <div id="sidebar">
                    <ul>
                        <li><a href="/instructor-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/instructor-students"><i className="fas fa-user"></i> Students</a></li>
                        <li><a href="/instructor-grades"><i className="fas fa-clipboard-list"></i> Grades</a></li>
                        <li><a href="/instructor-exams"><i className="fas fa-pen"></i> Exams</a></li>
                        <li><a href="/instructor-course-management"><i className="fas fa-book-open"></i> Course Management</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>

                {/* Main Content */}
                <main id="main-content">
                    <div id="student-info">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/0/0b/Professor_Steven_Chu_ForMemRS_headshot.jpg"
                            alt="Student Photo"
                        />
                        <p>Name: Ross</p>
                        <p>College: College of Arts and Sciences</p>
                        <p>Department: Computer Science</p>
                        <p>Email: ross@email.com</p>
                        <p>Current Courses: Web Development, Data Mining</p>
                        <a href="update-profile">
                            <button type="button">Update Profile</button>

                        </a>
                    </div>
                </main>
            </div>
        </>
    );
};

export default InstructorDashboard;
