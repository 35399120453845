import React from 'react';
import './main.css'; // Make sure the path is correct

const QADashboard = () => {
    return (
        <div id="wrapper">

            <div id="sidebar">
                <ul>
                    <li><a href="qa-dashboard"><i className="fas fa-tachometer-alt"></i> QA Dashboard</a></li>
                    <li><a href="qa-course-review"><i className="fas fa-book"></i> Review</a></li>
                    <li><a href="qa-audit"><i className="fas fa-file-alt"></i> Audits & Evaluations</a></li>
                    <li><a href="qa-student-performance"><i className="fas fa-chart-line"></i> Student Performance</a></li>
                    <li><a href="qa-qality-policies"><i className="fas fa-cogs"></i> Process and Policies </a></li>
                    <li><a href="messages-dashboard"><i className="fas fa-cogs"></i> Messages </a></li>
                    <li><a href="homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                </ul>
            </div>

            <main id="main-content">

                <div id="student-info">
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/0/0b/Professor_Steven_Chu_ForMemRS_headshot.jpg"
                        alt="Student Photo"
                    />
                    <p>Name: Ross</p>
                    <p>College: College of Arts and Sciences</p>
                    <p>Department: Computer Science</p>
                    <p>Email: ross@email.com</p>
                    <p>Current Courses: Web Development, Data Mining</p>
                    <a href="update-profile">
                        <button type="button">Update Profile</button>

                    </a>
                </div>
                <h1>QA Officer Dashboard</h1>
                <div className="card">
                    <h2>Pending Courses for Review</h2>
                    <p>12</p>
                </div>
                <div className="card">
                    <h2>Recent Audits</h2>
                    <p>3 this month</p>
                </div>
                <div className="card">
                    <h2>Student Performance</h2>
                    <p>Average: 75%</p>
                </div>
                <div className="card">
                    <h2>Compliance Checklist</h2>
                    <p>8/10 Completed</p>
                </div>
            </main>
        </div>
    );
};

export default QADashboard;
