import React from 'react';
import './main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog, faBook, faChartBar, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
const AdminDashboard = () => {
    const nextStep = (step) => {
        // Implement your logic here
    };

    return (
        <div>
            <nav id="top-navbar">
                <h1>Admin Dashboard</h1>
            </nav>

            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="admin-dashboard"><FontAwesomeIcon icon={faUsersCog} /> Admin Dashboard</a></li>
                        <li><a href="admin-user-management"><FontAwesomeIcon icon={faUsersCog} /> User Management</a></li>
                        <li><a href="admin-course-management"><FontAwesomeIcon icon={faBook} /> Course Management</a></li>
                        <li><a href="admin-analytics"><FontAwesomeIcon icon={faChartBar} /> Analytics</a></li>
                        <li><a href="messages-dashboard"><FontAwesomeIcon icon={faCogs} /> Messages</a></li>
                        <li><a href="homepage"><FontAwesomeIcon icon={faSignOutAlt} /> Log out</a></li>
                    </ul>
                </div>

                <main id="main-content">
                    <div id="student-info">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/0/0b/Professor_Steven_Chu_ForMemRS_headshot.jpg" alt="Student Photo" />
                        <p>Name: Ross</p>
                        <p>College: College of Arts and Sciences</p>
                        <p>Department: Computer Science</p>
                        <p>Email: ross@email.com</p>
                        <p>Current Courses: Web Development, Data Mining</p>
                        <a href="update-profile">
                            <button type="button" >Update Profile</button>

                        </a>

                    </div>

                    <h1>Summary</h1>
                    <div className="card">
                        <h2>Total Users</h2>
                        <p>500</p>
                    </div>
                    <div className="card">
                        <h2>Total Courses</h2>
                        <p>50</p>
                    </div>
                    <div className="card">
                        <h2>Average Grade</h2>
                        <p>80%</p>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default AdminDashboard;