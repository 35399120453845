import React from 'react';
import './main.css';  // Make sure the path is correct

const InstructorExams = () => {
    return (
        <>
            <nav id="top-navbar">
                <h1>Exams - Instructor Dashboard</h1>
            </nav>

            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="/instructor-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/instructor-students"><i className="fas fa-user"></i> Students</a></li>
                        <li><a href="/instructor-grades"><i className="fas fa-clipboard-list"></i> Grades</a></li>
                        <li><a href="/instructor-exams"><i className="fas fa-pen"></i> Exams</a></li>
                        <li><a href="/instructor-course-management"><i className="fas fa-book-open"></i> Course Management</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>

                <main id="main-content">
                    <section id="exam-details">
                        <h2>Upcoming Exams</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Exam Title</th>
                                    <th>Deadline</th>
                                    <th>Total Marks</th>
                                    <th>Duration (min)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Midterm 1</td>
                                    <td>2023-11-15</td>
                                    <td>100</td>
                                    <td>90</td>
                                </tr>
                                <tr>
                                    <td>Midterm 2</td>
                                    <td>2023-12-05</td>
                                    <td>100</td>
                                    <td>90</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>

                    <section id="post-exam-section">
                        <h2>Post New Exam</h2>
                        <h4>Select Course:</h4>
                        <select id="course-dropdown">
                            <option value="webdev">Web Development</option>
                            <option value="datamining">Data Mining</option>
                            <option value="machinelearning">Machine Learning</option>
                            <option value="computernetworks">Computer Networks</option>
                        </select>

                        <form id="post-exam-form">
                            <label htmlFor="exam-title">Exam Title:</label>
                            <input type="text" id="exam-title" name="exam-title" required />

                            <label htmlFor="exam-date">Date:</label>
                            <input type="date" id="exam-date" name="exam-date" required />

                            <label htmlFor="total-marks">Total Marks:</label>
                            <input type="number" id="total-marks" name="total-marks" required />

                            <label htmlFor="duration">Duration (min):</label>
                            <input type="number" id="duration" name="duration" required />

                            <button type="submit">Post Exam</button>
                        </form>
                    </section>
                </main>
            </div>
        </>
    );
};

export default InstructorExams;
