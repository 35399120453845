import React, { useState } from 'react';
import './main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faUsersCog, faBook, faChartBar, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const AdminUserManagement = () => {
    const [userData, setUserData] = useState({
        userId: '1002063330',
        username: 'abc',
        email: 'studentname@example.com',
        role: 'student',
        status: 'active'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData({ ...userData, [name]: value });
    };

    return (
        <div id="wrapper">
            <div id="sidebar">
                <ul>
                    <li><a href="admin-dashboard"><FontAwesomeIcon icon={faUsersCog} /> Admin Dashboard</a></li>
                    <li><a href="admin-user-management"><FontAwesomeIcon icon={faUsersCog} /> User Management</a></li>
                    <li><a href="admin-course-management"><FontAwesomeIcon icon={faBook} /> Course Management</a></li>
                    <li><a href="admin-analytics"><FontAwesomeIcon icon={faChartBar} /> Analytics</a></li>
                    <li><a href="messages-dashboard"><FontAwesomeIcon icon={faCogs} /> Messages</a></li>
                    <li><a href="homepage"><FontAwesomeIcon icon={faSignOutAlt} /> Log out</a></li>
                </ul>
            </div>

            <main id="main-content">
                <h1>Edit User Data</h1>
                <form id="editUserForm">
                    <label htmlFor="userId">User ID:</label>
                    <input type="text" id="userId" name="userId" value={userData.userId} readOnly onChange={handleChange} />

                    <label htmlFor="username">Username:</label>
                    <input type="text" id="username" name="username" value={userData.username} onChange={handleChange} />

                    <label htmlFor="email">Email:</label>
                    <input type="email" id="email" name="email" value={userData.email} onChange={handleChange} />

                    <label htmlFor="role">Role:</label>
                    <select id="role" name="role" value={userData.role} onChange={handleChange}>
                        <option value="student">Student</option>
                        <option value="instructor">Instructor</option>
                    </select>

                    <label htmlFor="status">Status:</label>
                    <select id="status" name="status" value={userData.status} onChange={handleChange}>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>

                    <button type="button" style={{ marginRight: '10px' }} onClick={() => alert('Changes submitted (mock action)')}>Submit Changes</button>
                    <button type="button" style={{ marginRight: '10px' }} onClick={() => alert('Monitoring User Activity (mock action)')}>Monitor User Activity</button>

                    <button type="button" onClick={() => alert('Permissions (mock action)')}>Permissions</button>
                </form>

                <h2>User Log</h2>
                <table id="userLogTable">
                    <thead>
                        <tr>
                            <th>Log ID</th>
                            <th>Action</th>
                            <th>Timestamp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>User Login</td>
                            <td>2023-09-25 10:00:00</td>
                        </tr>
                        {/* More rows can go here */}
                    </tbody>
                </table>
            </main>
        </div>
    );
};

export default AdminUserManagement;
