import React from 'react';
import './main.css'; // Make sure the path to your CSS is correct

const ProgramCoordinator = () => {
    return (
        <div id="wrapper">
            <div id="sidebar">
                <ul>
                    <li><a href="pc-home"><i className="fas fa-home"></i> Dashboard</a></li>
                    <li><a href="program-coordinator"><i className="fas fa-tachometer-alt"></i> Profile</a></li>
                    <li><a href="pc-course-management"><i className="fas fa-book"></i> Course Management</a></li>
                    <li><a href="pc-program-management"><i className="fas fa-book"></i> Program Management</a></li>
                    <li><a href="messages-dashboard"><i className="fas fa-clipboard-check"></i> Messages</a></li>
                    <li><a href="homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                </ul>
            </div>

            <main id="main-content">
                <h1>Welcome, Program Coordinator</h1>

                {/* Dashboard Widgets */}
                <div id="dashboard-widgets">
                    <div className="widget">
                        <h2>Total Courses</h2>
                        <p>127</p>
                    </div>
                    <div className="widget">
                        <h2>Active Faculty</h2>
                        <p>34</p>
                    </div>
                    <div className="widget">
                        <h2>Current Students</h2>
                        <p>1,248</p>
                    </div>
                    <div className="widget">
                        <h2>Upcoming Reviews</h2>
                        <p>5</p>
                    </div>
                </div>

                {/* To-Do List */}
                <div id="to-do-list">
                    <h2>To-Do List</h2>
                    <ul>
                        <li>Review course syllabus for Web Development 101</li>
                        <li>Meet with faculty to discuss midterm evaluations</li>
                        <li>Analyze latest student performance data</li>
                        <li>Check messages and respond to student inquiries</li>
                    </ul>
                </div>

                {/* Calendar */}
                <div id="calendar">
                    <h2>Calendar</h2>
                    <p>October 4th: Faculty Meeting</p>
                    <p>October 11th: Midterm Exams Start</p>
                    <p>October 25th: Midterm Exams End</p>
                </div>

                {/* Spotlights */}
                <div id="spotlight">
                    <h2>Spotlights</h2>
                    <div id="faculty-spotlight">
                        <h3>Faculty of the Month: Prof. John Smith</h3>
                        <p>Specializing in AI and machine learning. Published three papers this year.</p>
                    </div>
                    <div id="student-spotlight">
                        <h3>Student of the Month: Emily Williams</h3>
                        <p>Has an excellent track record and is leading the college coding competition.</p>
                    </div>
                </div>

                {/* Summary Stats */}
                <div id="summary-stats">
                    <h2>Program Metrics</h2>
                    <p>Average Course Rating: 4.5/5</p>
                    <p>Average Faculty Rating: 4.3/5</p>
                    <p>Student Retention Rate: 90%</p>
                </div>
            </main>
        </div>
    );
};

export default ProgramCoordinator;
