import React from 'react';
import './main.css';  // Make sure the path is correct

const StudentSubjects = () => {
    // You might pull this data from an API, but for now, let's keep it static.
    const courses = [
        {
            img: "https://miro.medium.com/v2/resize:fit:1400/1*aTYOTFS4Vkr-nwHNML3GvQ.jpeg",
            title: "CSE5305 - UI/UX Design",
        },
        {
            img: "https://builtin.com/cdn-cgi/image/f=auto,quality=80,width=752,height=435/https://builtin.com/sites/www.builtin.com/files/styles/byline_image/public/2021-12/machine-learning-examples-applications.png",
            title: "Machine Learning",
        },
        // ... and so on
    ];

    return (
        <div>
            <nav id="top-navbar">
                <h1>My Subjects</h1>
            </nav>

            <div id="wrapper">

                {/* Side Navigation */}
                <div id="sidebar">
                    <ul>
                        <li><a href="/student-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/student-subjects"><i className="fas fa-book"></i> My Subjects</a></li>
                        <li><a href="/student-grades"><i className="fas fa-star"></i> Grades</a></li>
                        <li><a href="/student-exams"><i className="fas fa-file-alt"></i> Exams</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>

                <main id="main-content">
                    <div id="courses">
                        {courses.map((course, index) => (
                            <a href="student-individual-course" key={index}>
                                <div className="course-card">
                                    <img src={course.img} alt={`Course ${index + 1}`} />
                                    <h2>{course.title}</h2>
                                </div>
                            </a>
                        ))}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default StudentSubjects;
