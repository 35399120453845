import React from 'react';
import './main.css'; // Make sure the path is correct

const StudentDashboard = () => {
    return (
        <div>
            <nav id="top-navbar">
                <h1>Student Dashboard</h1>
            </nav>

            <div id="wrapper">

                {/* Side Navigation */}
                <div id="sidebar">
                    <ul>
                        <li><a href="/student-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/student-subjects"><i className="fas fa-book"></i> My Subjects</a></li>
                        <li><a href="/student-grades"><i className="fas fa-star"></i> Grades</a></li>
                        <li><a href="/student-exams"><i className="fas fa-file-alt"></i> Exams</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>

                {/* Main Content */}
                <main id="main-content">

                    <div id="student-info">
                        <img src="https://www.richardsphotography.com/wp-content/uploads/2019/07/Medical-Student-Headshot-779_pp1.jpg" alt="Student Photo" />
                        <p>Student ID: 1002063330</p>
                        <p>Name: Siri</p>
                        <p>Email: siriv2001@gmail.com</p>
                        <p>Program: Masters in Computer Science</p>
                        <a href="update-profile">
                            <button type="button" >Update Profile</button>

                        </a>
                    </div>


                </main>



            </div>
        </div>

    );
};

export default StudentDashboard;
