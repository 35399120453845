import React from 'react';
import '../webpages/main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog, faBook, faChartBar, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';




const AdminAnalytics = () => {
    return (
        <div>
            <nav id="top-navbar">
                <h1>Admin Dashboard</h1>
            </nav>

            <div id="sidebar">
                <ul>
                    <li><a href="admin-dashboard"><FontAwesomeIcon icon={faUsersCog} /> Admin Dashboard</a></li>
                    <li><a href="admin-user-management"><FontAwesomeIcon icon={faUsersCog} /> User Management</a></li>
                    <li><a href="admin-course-management"><FontAwesomeIcon icon={faBook} /> Course Management</a></li>
                    <li><a href="admin-analytics"><FontAwesomeIcon icon={faChartBar} /> Analytics</a></li>
                    <li><a href="messages-dashboard"><FontAwesomeIcon icon={faCogs} /> Messages</a></li>
                    <li><a href="homepage"><FontAwesomeIcon icon={faSignOutAlt} /> Log out</a></li>
                </ul>
            </div>

            <main id="main-content">
                <h1>Admin Analytics</h1>
                <div className="analytics-container">
                    <div className="metric">
                        <h3>Total Users</h3>
                        <p id="totalUsers">0</p>
                    </div>
                    <div className="metric">
                        <h3>Active Courses</h3>
                        <p id="activeCourses">0</p>
                    </div>
                    <div className="chart-container">
                        <h3>User Engagement</h3>
                        <canvas id="userEngagementChart"></canvas>
                    </div>
                    <div className="chart-container">
                        <h3>Course Performance</h3>
                        <canvas id="coursePerformanceChart"></canvas>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default AdminAnalytics;