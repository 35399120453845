import React, { useState } from 'react';
import './main.css';  // make sure you have a file named main.css for the styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBook, faStar, faFileAlt, faEnvelope, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


const StudentGrades = () => {
    // Let's keep track of the feedback form steps
    const [step, setStep] = useState(1);

    const nextStep = (currentStep) => {
        setStep(currentStep + 1);
    };

    return (
        <div>
            <nav id="top-navbar">
                <h1>Grades</h1>
            </nav>

            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="/student-dashboard"><i className="fas fa-home"></i> Home</a></li>
                        <li><a href="/student-subjects"><i className="fas fa-book"></i> My Subjects</a></li>
                        <li><a href="/student-grades"><i className="fas fa-star"></i> Grades</a></li>
                        <li><a href="/student-exams"><i className="fas fa-file-alt"></i> Exams</a></li>
                        <li><a href="/messages"><i className="fas fa-envelope"></i> Messages</a></li>
                        <li><a href="/homepage"><i className="fas fa-sign-out-alt"></i> Log out</a></li>
                    </ul>
                </div>
                <main id="main-content">
                    <div id="grade-header">
                        <h1>CGPA: 3.8</h1>
                    </div>

                    <div id="student-details">
                        <p>Student ID: 1002063330</p>
                        <p>College: Some College</p>
                        <p>Program: Masters in Computer Science</p>
                        <p>Semester: Fall 2023</p>
                    </div>

                    <h2>Grades</h2>

                    <table id="grade-table">
                        <thead>
                            <tr>
                                <th>Course Code</th>
                                <th>Title</th>
                                <th>Grade</th>
                                <th>Completion</th>
                                <th>Units</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>CS101</td>
                                <td>Intro to Computer Science</td>
                                <td>2.5</td>
                                <td>Completed</td>
                                <td>3</td>
                            </tr>
                            {/* Add more rows here */}
                        </tbody>
                    </table>
                </main>
            </div>
        </div>
    );
};
export default StudentGrades;
