import React, { useState } from 'react';
import './main.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog, faBook, faChartBar, faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
const UpdateProfile = () => {
    const [formData, setFormData] = useState({
        name: '',
        college: '',
        department: '',
        email: '',
        currentCourses: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted: ", formData);
    };

    return (
        <div>
            <nav id="top-navbar">
                <h1>Profile Dashboard</h1>
            </nav>

            <div id="wrapper">
                <div id="sidebar">
                    <ul>
                        <li><a href="admin-user-management"><FontAwesomeIcon icon={faUsersCog} /> User Management</a></li>
                        <li><a href="admin-course-management"><FontAwesomeIcon icon={faBook} /> Course Management</a></li>
                        <li><a href="admin-analytics"><FontAwesomeIcon icon={faChartBar} /> Analytics</a></li>
                        <li><a href="messages-dashboard"><FontAwesomeIcon icon={faCogs} /> Messages</a></li>
                        <li><a href="homepage"><FontAwesomeIcon icon={faSignOutAlt} /> Log out</a></li>
                    </ul>
                </div>

                <main id="main-content">

                    <div className="update-profile">
                        <h1>Update Profile</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input type="text" id="name" name="name" value={formData.name} onChange={handleInputChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="college">College:</label>
                                <input type="text" id="college" name="college" value={formData.college} onChange={handleInputChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="department">Department:</label>
                                <input type="text" id="department" name="department" value={formData.department} onChange={handleInputChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="currentCourses">Current Courses:</label>
                                <input type="text" id="currentCourses" name="currentCourses" value={formData.currentCourses} onChange={handleInputChange} />
                            </div>

                            <button type="submit">Save Changes</button>
                        </form>
                    </div>
                </main>
            </div>

        </div>
    );

};



export default UpdateProfile;
