import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './main.css'; // Make sure this path is accurate

const Login = () => {
    const [formData, setFormData] = useState({ username: '', password: '' });
    const navigate = useNavigate(); // for programmatic navigation

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Mock database of username-password-role combinations
        const userDatabase = {
            'admin': { password: 'password', role: 'admin-dashboard' },
            'instructor': { password: 'password', role: 'instructor-dashboard' },
            'qa': { password: 'password', role: 'qa-dashboard' },
            'student': { password: 'password', role: 'student-dashboard' },
            'pc': { password: 'password', role: 'pc-home' },

        };

        const user = formData.username.toLowerCase();
        const pass = formData.password;

        if (userDatabase[user] && userDatabase[user].password === pass) {
            navigate(`/${userDatabase[user].role}`);
        } else {
            console.log('Invalid credentials');
        }
    };

    return (
        <>
            <nav>
                <ul>
                    <li><a href="/homepage">Homepage</a></li>
                    <li><a href="/contact-us">Contact Us</a></li>
                    <li><a href="/about-us">About Us</a></li>
                    <li><a href="/login">Login</a></li>
                    <li><a href="/register">Register</a></li>
                </ul>
            </nav>

            <section>
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="username">Username:</label><br />
                    <input type="text" id="username" name="username" required onChange={handleChange} /><br /><br />

                    <label htmlFor="password">Password:</label><br />
                    <input type="password" id="password" name="password" required onChange={handleChange} /><br /><br />

                    <input type="submit" value="Login" />
                </form>
                <p><a href="/register">Register</a></p>
                <p><a href="/forgot-password">Forgot Password?</a></p>
            </section>

            <footer>
                <p>Copyright &copy; 2023, All Rights Reserved.</p>
            </footer>
        </>
    );
};

export default Login;
