import React from 'react';
import './main.css'; // Make sure the path is correct

const Register = () => {
    return (
        <div>
            <nav>
                <ul>
                    <li><a href="/homepage">Homepage</a></li>
                    <li><a href="/contact-us">Contact Us</a></li>
                    <li><a href="/about-us">About Us</a></li>
                    <li><a href="/login">Login</a></li>
                    <li><a href="/register">Register</a></li>
                    <li><a href="https://sxs7311.uta.cloud/blog/">Blog</a></li>
                </ul>
            </nav>

            <section>
                <h2>Create a New Account</h2>
                <form action="#" method="post">
                    <label htmlFor="role">Role:</label><br />
                    <select id="role" name="role">
                        <option value="student">Student</option>
                        <option value="instructor">Instructor</option>
                        <option value="program-coordinator">Program Coordinator</option>
                        <option value="QA">QA Officer</option>
                        <option value="TA">TA</option>
                    </select><br /><br />

                    <label htmlFor="username">Username:</label><br />
                    <input type="text" id="username" name="username" required /><br /><br />

                    <label htmlFor="email">Email:</label><br />
                    <input type="email" id="email" name="email" required /><br /><br />

                    <label htmlFor="password">Password:</label><br />
                    <input type="password" id="password" name="password" required /><br /><br />

                    <label htmlFor="confirmPassword">Confirm Password:</label><br />
                    <input type="password" id="confirmPassword" name="confirmPassword" required /><br /><br />

                    <input type="submit" value="Register" />
                </form>
            </section>

            <footer>
                <p>Copyright &copy; 2023, All Rights Reserved.</p>
            </footer>
        </div>
    );
};

export default Register;
